import React from 'react';
import {
    Grid,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const InvoiceItem = (props) => {

    return (
        <Grid container className='invoice-item-wrapper'>
            <Grid item xs={11}>
                <div className='invoice-item'>
                    <Grid container>
                        <Grid item xs={1}>
                            <div 
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '90%',
                              }}>
                                {props.no}
                            </div>
                        </Grid>
                        <Grid item xs={9} style={{
                            boxSizing:'border-box',
                            paddingRight: '15px',
                        }}>
                            <div className='truncate' style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}>
                                {props.description}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            {props.rate}
                        </Grid>
                        <Grid item xs={1}>
                            {props.quantity}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={1} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <IconButton onClick={e=>props.onDelete(props.id)} aria-label="delete" color="secondary">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default InvoiceItem;
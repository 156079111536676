import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Container,
    Button, 
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    DialogContent,
    Grid,
    ListItem,
    ListItemText,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import InvoiceItem from './InvoiceItem';
import axios from 'axios';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const InvoiceDialog = (props) => {

    const { currentUser } = useAuthContext();

    const [EVID, setEVID] = useState(props.invoiceID);
    const [customer, setCustomer] = useState(props.customer);
    const [customerID, setCustomerID] = useState(props.customerID);
    const [invoiceItems, setInvoiceItems] = useState(props.invoiceItems);
    const [invoiceNo, setInvoiceNo] = useState(props.invoiceNo);
    const [initInvoiceNo, setInitInvoiceNo] = useState(props.invoiceNo);
    const [status, setStatus] = useState(props.status);
    const [paidDate, setPaidDate] = useState(props.paidDate);
    const [invoicedDate, setInvoicedDate] = useState(props.invoicedDate);
    const [invoicePONo, setInvoicePONo] = useState(props.invoicePONo);
    const [invoiceTerms, setInvoiceTerms] = useState(props.invoiceTerms);

    const [displayInvoiceItems, setDisplayInvoiceItems] = useState('');
    const [refresh, setRefresh] = useState(false);

    const { type, opened, onClose, onSave } = props;
    
    // Display customers options
    const [displayCustomers, setDisplayCustomers] = useState('');

    useEffect(() => {

        let display_array = [];

        for(let i = 0; i < props.customersData.length; i++) {
            display_array.push(
                <MenuItem value={props.customersData[i]._id} name={props.customersData[i].name}>{props.customersData[i].name}</MenuItem>
            )
        }

        setDisplayCustomers(display_array);
    }, [props]);
    
    const handleSelectCustomer = (e) => {
        if(e.target.value) {
            if(customerID !== e.target.value) {
                // if different customer is selected
                setCustomer(props.customersData.find((o) => (o._id === e.target.value)).name);
                setCustomerID(e.target.value);
    
                // delete all current invoice items b/c jobs shouldn't be mixed
                setInvoiceItems([]);
            }
        }
    }
    
    useEffect(() => {
        setEVID(props.invoiceID);
        setInvoiceNo(props.invoiceNo);
        setInitInvoiceNo(props.invoiceNo);
        setCustomer(props.customer);
        setCustomerID(props.customerID);
        setInvoiceItems(props.invoiceItems);
        setStatus(props.status);
        setPaidDate(props.paidDate);
        setInvoicedDate(props.invoicedDate);
        setInvoicePONo(props.invoicePONo);
        setInvoiceTerms(props.invoiceTerms);
        setRefresh(refresh ? false : true);
    }, [opened]);

    const clear = () => {
        setEVID('');
        setInvoiceNo('');
        setInitInvoiceNo('');
        setCustomerID('');
        setCustomer('');
        setStatus('');
        setPaidDate('');
        setInvoicedDate('');
        setInvoiceItems([]);
        setInvoicePONo('');
        setInvoiceTerms('');
        setError('');
        setRefresh(refresh ? false : true);
    }
    
    // adds job as invoice item
    function handleAddJob(job_obj) {

        setRefresh(refresh ? true : false); // flipped refresh because function is passed (deplayed invoke)

        let newInvoiceItems = invoiceItems;
        newInvoiceItems.push({
            id: job_obj._id,
            job_no: job_obj.job_no,
            type: job_obj.type,
            description: job_obj.description,
            note: job_obj.note,
            is_analysis: job_obj.is_analysis,
            is_design: job_obj.is_design,
            quantity: job_obj.quantity,
            rate: job_obj.rate,
        });
        setInvoiceItems(newInvoiceItems);

        setOpenedSelector(false);
    }

    const [delRefresh, setDelRefresh] = useState(false);

    // deletes job from invoice
    function handleDeleteItem(id) {
        let deleteIndex = invoiceItems.findIndex((item) => {return item.id === id});
        let newInvoiceItems = invoiceItems;
        newInvoiceItems.splice(deleteIndex, 1);
        setInvoiceItems(newInvoiceItems);

        setDelRefresh(delRefresh ? false : true);
    }


    useEffect(() => {
        let display_array = [];

        for (let i = 0; i < invoiceItems.length; i++) {
            display_array.push(
                <InvoiceItem
                    onDelete={handleDeleteItem}
                    no={invoiceItems[i].job_no}
                    id={invoiceItems[i].id}
                    customer={invoiceItems[i].customer}
                    description={invoiceItems[i].description}
                    rate={invoiceItems[i].rate}
                    quantity={invoiceItems[i].quantity} />
            )
        }

        setDisplayInvoiceItems(display_array);

    }, [refresh, delRefresh, props]);


    

    const [ customerJobs, setCustomerJobs ] = useState([]);


    // Get all jobs of customer (to show available to add)
    async function loadJobs() {

        if(customerID) {
            try {
                let res = await axios.get(`${API_URL}/jobs/customer/${customerID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': currentUser,
                    }
                });

                let availableJobs = res.data.filter((item) => {return ((item.invoiced === false) || (item.invoiced === 'false'))});

                setCustomerJobs(availableJobs);
            } catch(error) {
                
                // TODO: error handling
                console.error(error);
            }

        }
    }

    useEffect(() => {
        loadJobs();
    }, [customerID]);


    // Selector pop up 
    const [ openedSelector, setOpenedSelector ] = useState(false);

    const [ displayJobsToAdd, setDisplayJobsToAdd ] = useState([]);

    function handleOpenSelector() {
        setOpenedSelector(true);
        setRefresh(refresh ? false : true);
    }

    function handleCloseSelector() {
        setOpenedSelector(false);
        setRefresh(refresh ? false : true);
    } // note: job/item saving fn is found above

    useEffect(() => {
        let display_array = [];

        // TODO: present array filtered by customerJobs array with invoiceItems array (prevent duplicate jobs)
        // .filter((el) => {
        //     return invoiceItems.some((f) => {
        //         return f._id == el._id;
        //     });
        // });

        for(let i = 0; i < customerJobs.length; i++) {
            display_array.push(
                <ListItem button onClick={() => {handleAddJob(customerJobs[i])}}>
                    <ListItemText primary={`#${customerJobs[i].job_no} ${customerJobs[i].description ? '('+customerJobs[i].description+')' : ''}`} />
                </ListItem>
            );
        }

        if(display_array.length === 0) {
            display_array.push(
                <div style={{
                    padding:'0 24px 24px 24px',
                    color: '#777777',
                }}>
                    No active jobs.
                </div>
            );
        }

        setDisplayJobsToAdd(display_array);
    }, [customerJobs, invoiceItems]);



    // Chief actions

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleClose = () => {
        clear();
        onClose();
    }

    async function handleSave () {
        /*
            Invoice Schema (backend):
            - customer (customer.name)
            - customer_id (customer._id)
            - invoice_date (Date when invoice gets "sent" - manual)
            - payment_date (Date when invoice gets "paid" - manual)
            - status ("created/sent/paid")
            - lastUpdated (internal)
            - createdAt (internal)
        */

        setError('');

        if(!loading) {
            setLoading(true);

            let postBody = {
                customer: customer,
                customer_id: customerID,
                invoiceItems: invoiceItems,
                invoice_date: invoicedDate,
                payment_date: paidDate,
                invoice_terms: invoiceTerms,
                invoice_po_no: invoicePONo,
                status: status,
                gst: 0.05,
            };

            if(type === 'edit') {
                if(initInvoiceNo !== invoiceNo) {
                    postBody['invoice_no'] = invoiceNo;
                }
                
                try {
                    let res = await axios.patch(`${API_URL}/invoices/${EVID}`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });

                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Connection with server is bad');
                    }
                }

            }

            if (type === 'create') {

                postBody['invoice_no'] = invoiceNo;
                
                try {
                    let res = await axios.post(`${API_URL}/invoices/`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });

                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Connection with server is bad');
                    }
                }
            }

        }
    }



    return (
        <>
        <Dialog fullScreen open={opened} >
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <IconButton edge='start' aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <h4 style={{
                        margin: '0 1.5vw 0 10px',
                    }}>
                        {(type === 'edit') ? 'Edit Invoice' : 'Create New Invoice'}
                    </h4>
                </div>
                <div className='dialg-title-action'>
                    
                    <Button disabled={loading} onClick={e=>{
                        if(opened) {
                            handleSave();
                        }
                    }} color="primary" variant="contained" size="large" disableElevation>
                        
                        {!loading ? ((type === 'edit') ? 'Save Changes' : 'Create Invoice') : (
                            <>
                                <CircularProgress disableShrink size={20} style={{
                                    marginRight: 10,
                                    marginLeft: -5,
                                    color: '#777777',
                                }} />
                                Loading
                            </>
                        )}
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Container 
                  style={{ padding:'0 0 1.5vw 0' }}
                  maxWidth='md'>
                    {error ? (
                        <Alert severity="error">{error}</Alert>
                    ) : ''}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '2vw 0 0 0',
                            }}>
                                {(type === 'edit') ? 'Edit Existing Invoice' : 'Create New Invoice'}
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={invoiceNo} onChange={e=>setInvoiceNo(e.target.value)} variant="outlined" label="Invoice Number" fullWidth margin="normal" />
                            
                            <FormControl 
                                required
                                variant="outlined"
                                margin="normal" 
                                fullWidth>
                                <InputLabel id="customer-label">Customer</InputLabel>
                                <Select
                                    labelId="customer-label"
                                    value={customerID}
                                    onChange={handleSelectCustomer} 
                                    label="Customer">
                                    <MenuItem value="">
                                        <em>Select Customer</em>
                                    </MenuItem>
                                    {displayCustomers}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="date"
                                        variant="outlined"
                                        fullWidth
                                        label="Invoice Date"
                                        type="date"
                                        margin="normal"
                                        value={invoicedDate}
                                        onChange={e=>setInvoicedDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Grid>
                                <Grid item xs={6}>

                                    <TextField
                                        id="date"
                                        variant="outlined"
                                        fullWidth
                                        label="Payment Date"
                                        type="date"
                                        margin="normal"
                                        value={paidDate}
                                        onChange={e=>setPaidDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl 
                                        variant="outlined"
                                        fullWidth>
                                        <InputLabel id="customer-label">Status</InputLabel>
                                        <Select
                                            labelId="customer-label"
                                            value={status}
                                            onChange={e=>{if(e.target.value){setStatus(e.target.value);}}} 
                                            label="Customer">
                                            <MenuItem value=""><em>Select Invoice Status</em></MenuItem>
                                            <MenuItem value="Created">Created</MenuItem>
                                            <MenuItem value="Sent">Sent</MenuItem>
                                            <MenuItem value="Paid">Paid</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                                
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '2vw 0 0 0',
                            }}>
                                Invoice Details
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={invoicePONo} onChange={e=>setInvoicePONo(e.target.value)} variant="outlined" label="P.O. No." fullWidth margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={invoiceTerms} onChange={e=>setInvoiceTerms(e.target.value)} variant="outlined" label="Terms" fullWidth margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '2vw 0 0 0',
                            }}>
                                Invoice Items
                            </h3>
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            style={{
                                marginBottom:0,
                                paddingBottom:0,
                            }}>
                            {(invoiceItems.length === 0) ? '' : (
                                <>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <div className='invoice-items-header'>
                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <h5 className='offset-left-20'>Job #</h5>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <h5>Description</h5>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <h5>Rate</h5>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <h5>Hours</h5>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {displayInvoiceItems}
                                </>
                            )}
                            {/* <h5 style={{color:'#8D8D8D', fontWeight: 400,}}></h5> */}
                        </Grid>
                        <Grid
                            item
                            xs={12} 
                            style={{
                                paddingTop:0,
                                display: 'flex',
                                justifyContent:'center',
                            }}>
                            <Button 
                                variant="text" 
                                color="primary" 
                                size="large" 
                                disableElevation 
                                onClick={e=>handleOpenSelector()}
                                style={{
                                    fontSize:'1rem',
                                    width: '100%',
                                }}>
                                    <AddIcon style={{
                                        marginRight: 7
                                    }} />
                                Add Item
                            </Button>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <h3>
                                Invoice Preview
                            </h3>
                        </Grid> */}
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
        
        <Dialog onClose={handleCloseSelector} aria-labelledby="customized-dialog-title" open={openedSelector}>
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <IconButton edge='start' aria-label="close" onClick={handleCloseSelector}>
                        <CloseIcon />
                    </IconButton>
                    <h4 style={{
                        margin: '0 1.5vw 0 10px',
                    }}>
                        Add Job
                    </h4>
                </div>
            </DialogTitle>
            <DialogContent>
                {displayJobsToAdd}
            </DialogContent>
        </Dialog>
        </>
    );
}

export default InvoiceDialog;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navigation from '../components/Navigation';
import JobList from '../components/JobList';
import JobDialog from '../components/JobDialog';
import { Container, Grid, Snackbar, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';
import DeleteDialog from '../components/DeleteDialog';

const Jobs = () => {


    // Getting Jobs data

    const { currentUser } = useAuthContext();

    const [jobsData, setJobsData] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataRefresh, setDataRefresh] = useState(false);

    async function loadJobs() {
        setDataLoading(true);

        try {
            let res = await axios.get(`${API_URL}/jobs/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            let functional_list = res.data.map((item) => {return {...item, isAnalysis: item.is_analysis === 'true', isDesign: item.is_design === 'true', action: {job_obj: item, edit_fn: handleListEdit, delete_fn: handleListDelete}} });
            setJobsData(functional_list);
            setDataLoading(false);

        } catch(error) {
            setDataLoading(false);
        }
    }

    async function loadCustomers() {
        try {
            let res = await axios.get(`${API_URL}/customers/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setCustomersData(res.data);

        } catch(error) {
            setDataLoading(false);
        }
    }

    useEffect(() => {
        loadJobs();
        loadCustomers();
    }, [dataRefresh])

    function handleListEdit(job) {
        handleOpenJob('edit', job);
    }

    function handleListDelete(id) {
        handleDeleteJob(id);
    }





    // Job Dialog

    const [openedJob, setOpenedJob] = useState(false);
    const [jobDialogType, setJobDialogType] = useState('');

    const [JBID, setJBID] = useState('');
    const [jobCustomer, setJobCustomer] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [description, setDescription] = useState('');
    const [isAnalysis, setIsAnalysis] = useState(false);
    const [isDesign, setIsDesign] = useState(false);
    const [note, setNote] = useState('');
    const [rate, setRate] = useState('');
    const [quantity, setQuantity] = useState('');
    const [jobType, setJobType] = useState('');
    const [jobNo, setJobNo] = useState('');

    function handleOpenJob(type, job) {
        // type == create / edit
        if(type === 'create') {
            setJobDialogType('create');
        }
        if(type === 'edit') {
            setJBID(job._id);
            setJobNo(job.job_no);
            setJobType(job.type);
            setJobCustomer(job.customer);
            setCustomerID(job.customer_id);
            setDescription(job.description);
            setIsAnalysis(job.is_analysis === 'true');
            setIsDesign(job.is_design === 'true');
            setNote(job.note);
            setRate(job.rate);
            setQuantity(job.quantity);

            setJobDialogType('edit');
        }
        setOpenedJob(true);
    }

    function handleCloseJob() {
        setJBID('');
        setJobNo('');
        setJobType('');
        setCustomerID('');
        setJobCustomer('');
        setDescription('');
        setIsAnalysis('');
        setIsDesign('');
        setNote('');
        setRate('');
        setQuantity('');

        setOpenedJob(false);
    }

    function handleSaveJob(type) {
        // AXIOS request is sent thru modal -- this is just to carry forward the saving interaction flow

        if(type === 'create') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseJob();
            setNotifySuccess({
                severity: 'success',
                message: 'Job created successfully!',
            });
        } else if (type === 'edit') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseJob();
            setNotifySuccess({
                severity: 'success',
                message: 'Job edited successfully!',
            });
        }
    }

    

    // Delete confirmation
    const [openedDelete, setOpenedDelete] = useState(false);
    const [confirmID, setConfirmID] = useState('');

    // main function - opens delete dialog
    const handleDeleteJob = (id) => {
        setOpenedDelete(true);
        setConfirmID(id);
    }

    // close situations: didn't delete
    const handleDeleteClose = () => {
        setOpenedDelete(false);
    }

    // close situation: after deletion (dialog components handles axios)
    const handleDeleteConfirm = () => {
        setDataRefresh(dataRefresh ? false : true);
        setOpenedDelete(false);
        setNotifySuccess({
            severity: 'success',
            message: 'Job deleted successfully!',
        });
    }


    // Dialog Feedback
    const [notifySuccess, setNotifySuccess] = useState({severity: 'closed', message: ''});


    return (
        <div className='app-container'>
            <Navigation />
            
            <Container maxWidth='lg'>
                <Grid container style={{
                    paddingBottom:'20px',
                }}>
                    <Grid item xs={6}>
                        <h3>Jobs</h3>
                    </Grid>
                    <Grid item xs={6} style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <Button variant="contained" color="primary" size="large" disableElevation onClick={e=>handleOpenJob('create')}>
                            Create Job
                        </Button>
                    </Grid>
                </Grid>

                <JobList
                  refresh={dataRefresh}
                  loading={dataLoading}
                  data={jobsData} />
                
            </Container>

            <JobDialog
                customersData={customersData}
                jobNo={jobNo}
                jobID={JBID}
                customer={jobCustomer}
                customerID={customerID}
                jobType={jobType}
                description={description}
                isAnalysis={isAnalysis}
                isDesign={isDesign}
                note={note}
                quantity={quantity}
                rate={rate}
                opened={openedJob}
                type={jobDialogType}
                onClose={handleCloseJob}
                onSave={handleSaveJob} />
            
            <DeleteDialog
                deleteType='job'
                confirmID={confirmID}
                opened={openedDelete}
                onClose={handleDeleteClose}
                onConfirm={handleDeleteConfirm} />

            <Snackbar open={(notifySuccess.severity !== 'closed')} autoHideDuration={4000} onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})}>
                <Alert onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})} severity={notifySuccess.severity}>
                    {notifySuccess.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Jobs;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    Button,
    Container,
    Snackbar,
    Grid
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import Navigation from '../components/Navigation';
import InvoiceList from '../components/InvoiceList';
import InvoiceDialog from '../components/InvoiceDialog';
import DeleteDialog from '../components/DeleteDialog';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';
import { useHistory } from 'react-router';

const Invoices = () => {

    const { currentUser } = useAuthContext();

    const [customersData, setCustomersData] = useState([]);
    const [invoicesData, setInvoicesData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataRefresh, setDataRefresh] = useState(false); 

    async function loadInvoices() {
        setDataLoading(true);

        try {
            let res = await axios.get(`${API_URL}/invoices/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            let functional_list = res.data.map((item) => {return {...item, jobs: item.invoiceItems.length, action: {invoice_obj: item, edit_fn: handleListEdit, delete_fn: handleListDelete, pdf_fn: handleViewPDF}} });
            setInvoicesData(functional_list);
            setDataLoading(false);

        } catch(error) {
            setDataLoading(false);
        }
    }  

    async function loadCustomers() {

        try {
            let res = await axios.get(`${API_URL}/customers/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setCustomersData(res.data);

        } catch(error) {
            setDataLoading(false);
        }
    }

    useEffect(() => {
        loadInvoices();
        loadCustomers();
    }, [dataRefresh]);

    function handleListEdit(invoice) {
        handleOpenInvoice('edit', invoice);
    }

    function handleListDelete(id) {
        handleDeleteInvoice(id);
    }

    const history = useHistory();

    function handleViewPDF(id) {
        history.push(`./invoices/${id}`);
    }




    // Invoice Create/Edit Dialog

    const [openedInvoice, setOpenedInvoice] = useState(false);
    const [invoiceDialogType, setInvoiceDialogType] = useState('');

    const [invoiceNo, setInvoiceNo] = useState('');
    const [invoiceID, setInvoiceID] = useState('');
    const [invoiceCustomer, setInvoiceCustomer] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paidDate, setPaidDate] = useState('');
    const [invoicedDate, setInvoicedDate] = useState('');
    const [invoicePONo, setInvoicePONo] = useState('');
    const [invoiceTerms, setInvoiceTerms] = useState('');

    const handleOpenInvoice = (type, invoice) => {
        if(type === 'create') {
            setInvoiceDialogType('create');
        }
        if(type === 'edit') {
            setInvoiceDialogType('edit');

            setInvoiceNo(invoice.invoice_no);
            setInvoiceID(invoice._id);
            setInvoiceCustomer(invoice.customer);
            setCustomerID(invoice.customer_id);
            setInvoiceItems(invoice.invoiceItems);
            setInvoiceStatus(invoice.status);
            setInvoicedDate(invoice.invoice_date);
            setPaidDate(invoice.payment_date);
            setInvoicePONo(invoice.invoice_po_no);
            setInvoiceTerms(invoice.invoice_terms);
        }
        setOpenedInvoice(true);
    }

    const handleCloseInvoice = () => {
        setInvoiceNo('');
        setPaidDate('');
        setInvoiceID('');
        setInvoicedDate('');
        setInvoiceCustomer('');
        setInvoiceItems([]);
        setInvoiceStatus('');
        setCustomerID('');
        setInvoicePONo('');
        setInvoiceTerms('');

        setOpenedInvoice(false);
    }

    const handleSaveInvoice = (type) => {
        // 'type' may be redundant b/c invoiceDialogType but for the sake of coherency

        if(type === 'create') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseInvoice();
            setNotifySuccess({
                severity: 'success',
                message: 'Invoice created successfully!',
            });
        } else if (type === 'edit') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseInvoice();
            setNotifySuccess({
                severity: 'success',
                message: 'Invoice edited successfully!',
            });
        }
    }

    

    // Delete confirmation
    const [openedDelete, setOpenedDelete] = useState(false);
    const [confirmID, setConfirmID] = useState('');

    // main function - opens delete dialog
    const handleDeleteInvoice = (id) => {
        setOpenedDelete(true);
        setConfirmID(id);
    }

    // close situations: didn't delete
    const handleDeleteClose = () => {
        setOpenedDelete(false);
    }

    // close situation: after deletion (dialog components handles axios)
    const handleDeleteConfirm = () => {
        setDataRefresh(dataRefresh ? false : true);
        setOpenedDelete(false);
        setNotifySuccess({
            severity: 'success',
            message: 'Invoice deleted successfully!',
        });
    }

    const [notifySuccess, setNotifySuccess] = useState({severity: 'closed', message: ''});

    return (
        <div className='app-container'>
            <Navigation />
            
            <Container maxWidth='lg'>
                <Grid container style={{
                    paddingBottom:'20px',
                }}>
                    <Grid item xs={6}>
                        <h3>Invoices</h3>
                    </Grid>
                    <Grid item xs={6} style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          size="large" 
                          disableElevation 
                          onClick={e=>handleOpenInvoice('create')}>
                            Create Invoice
                        </Button>
                    </Grid>
                </Grid>

                <InvoiceList
                    refresh={dataRefresh}
                    loading={dataLoading}
                    data={invoicesData} />
                
            </Container>

            <InvoiceDialog
                invoiceNo={invoiceNo}
                invoiceID={invoiceID}
                customersData={customersData}
                customer={invoiceCustomer}
                customerID={customerID}
                invoiceItems={invoiceItems}
                type={invoiceDialogType}
                status={invoiceStatus}
                paidDate={paidDate}
                invoicedDate={invoicedDate}
                invoicePONo={invoicePONo}
                invoiceTerms={invoiceTerms}

                opened={openedInvoice}
                onClose={handleCloseInvoice}
                onSave={handleSaveInvoice} />
            
            <DeleteDialog
                deleteType='invoice'
                confirmID={confirmID}
                opened={openedDelete}
                onClose={handleDeleteClose}
                onConfirm={handleDeleteConfirm} />

            <Snackbar open={(notifySuccess.severity !== 'closed')} autoHideDuration={4000} onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})}>
                <Alert onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})} severity={notifySuccess.severity}>
                    {notifySuccess.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Invoices;
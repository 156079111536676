import './App.css';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './authentication/AuthContext';

import Login from './screens/Login';
import Jobs from './screens/Jobs';
import Dashboard from './screens/Dashboard';
import Invoices from './screens/Invoices';
import Customers from './screens/Customers';
import Inventory from './screens/Inventory';
import Invoice from './screens/Invoice';
import Accounts from './screens/Accounts';

function App() {

  function AuthRoute({ component: Component, ...rest}) {
    const { currentUser } = useAuthContext();

    return (
      <Route
        {...rest}
          render={(props) => 
            (currentUser) ? (
              <Component {...props} />
            ) : (
              <Redirect to='/login' />
            )
          }
        />
    )
  }

  return (
    <AuthProvider>
      <Switch>
        
        <Route exact path='/login' component={Login} />
        <AuthRoute exact path='/' component={Dashboard} />

        <AuthRoute exact path='/jobs' component={Jobs} />
        <AuthRoute exact path='/invoices' component={Invoices} />
        <AuthRoute exact path='/invoices/:id' component={Invoice} />
        <AuthRoute exact path='/customers' component={Customers} />
        <AuthRoute exact path='/inventory' component={Inventory} />
        <AuthRoute exact path='/accounts' component={Accounts} />

        {/* Concept  
        <AuthRoute exact path='/concept/components' component={Components} />
        <AuthRoute exact path='/concept/lists' component={Lists} />
        */}

      </Switch>
    </AuthProvider>
  );
}

export default App;

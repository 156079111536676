import { 
    Button, 
    CircularProgress, 
    CardHeader, 
    CardContent, 
    TextField,
    Container,
    Snackbar
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const Login = () => {

    const {currentUser, updateCurrentUser} = useAuthContext();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    function isValid() {
        let uError = false, pError = false;

        setUsernameError(false);
        setPasswordError(false);

        if(username.length === 0) {
            uError = true;
            setUsernameError(uError);
        }
        if(password.length === 0) {
            pError = true;
            setPasswordError(pError);
        }
        

        if(uError || pError) {
            return false;
        } else {
            return true;
        }
    }

    async function handleLogin(e) {
        e.preventDefault();

        let valid = isValid();

        if(!loading) {
            setLoading(true);
            setError('');

            if(valid) {
                try {
                    let res = await axios.post(`${API_URL}/auth/login`, {
                        email: username,
                        password: password
                    });
    
                    updateCurrentUser(res.data);
                    history.go(0);
                } catch (error) {
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Cannot communicate with server');
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
    }

    // Press enter key to login
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    }
    
    // If user lands on the login page while already logged in
    useEffect(() => {
        if (currentUser) {
            history.push('/');
        }
    }, []);


    
    // Forgot password 
    const [notifySuccess, setNotifySuccess] = useState({severity: 'closed', message: ''});

    const handleForgotPassword = () => {
        setNotifySuccess({
            severity: 'info',
            message: 'Please contact an admin-level account to help reset your password',
        })
    }


    return (
        <div className='app-container' >
            <Container maxWidth='xs'>
                    <div>
                        <CardHeader title="Max ERP" subheader="Login to Dashboard" />
                        <CardContent>
                            <TextField 
                              value={username} 
                              onChange={e=>setUsername(e.target.value.toLowerCase())} 
                              variant="outlined" 
                              label="Username" 
                              size="medium" 
                              fullWidth 
                              error={usernameError}
                              onKeyDown={e=>handleKeyDown(e)} />
                            <TextField 
                              value={password} 
                              onChange={e=>setPassword(e.target.value)} 
                              variant="outlined" 
                              label="Password" 
                              type="password"  
                              margin="normal" 
                              fullWidth 
                              error={passwordError}
                              onKeyDown={e=>handleKeyDown(e)} />

                            <Button
                              disabled={loading}
                              component={Link} to={'/'} 
                              variant="contained" 
                              color="primary" 
                              size="large" 
                              disableElevation 
                              onClick={e=>handleLogin(e)}
                              style={{
                                marginTop:10,
                            }}>
                                {!loading ? 'Log In' : (
                                    <>
                                        <CircularProgress disableShrink size={20} style={{
                                            marginRight: 10,
                                            marginLeft: -5,
                                            color: '#777777',
                                        }} />
                                        Loading
                                    </>
                                )}
                            </Button>
                            <Button 
                              variant="text" 
                              color="primary" 
                              size="large" 
                              disableElevation 
                              style={{
                                marginLeft:10,
                                marginTop:10,
                              }}
                              onClick={handleForgotPassword}>
                                Forgot Password
                            </Button>

                            {error ? (
                                <Alert style={{marginTop:18}} severity="error">{error}</Alert>
                            ) : ''}
                        </CardContent>
                    </div>


                <Snackbar open={(notifySuccess.severity !== 'closed')} autoHideDuration={10000} onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})}>
                    <Alert onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})} severity={notifySuccess.severity}>
                        <AlertTitle>Forgot Password</AlertTitle>
                        {notifySuccess.message}
                    </Alert>
                </Snackbar>

            </Container>
        </div>
    )
}

export default Login;
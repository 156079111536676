import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import Navigation from '../components/Navigation';
import {
    Container, Grid, Button, CircularProgress
} from '@material-ui/core';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { useAuthContext } from '../authentication/AuthContext';
import { PDFDownloadLink, Page, Text, View, Document, Image, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import Whitelabel from '../assets/whitelabel.png';
import InterBold from '../assets/Inter-SemiBold.ttf';
import InterMed from '../assets/Inter-Medium.ttf';
import InterReg from '../assets/Inter-Regular.ttf';

const MONTH = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const Invoice = () => {
    const { id } = useParams();
    const { currentUser } = useAuthContext();

    const [invoiceObj, setInvoiceObj] = useState({customer_id: 0});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [customerObj, setCustomerObj] = useState({});
    const [dataLoading, setDataLoading] = useState(true);

    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [invoiceDate, setInvoiceDate] = useState('');

    function returnDateString(strDate) {
        let date = parseInt(strDate);
        if (date === 1) {
            return `${date}st`;
        } else if (date === 2) {
            return `${date}nd`;
        } else if (date === 3) {
            return `${date}rd`;
        } else {
            return `${date}th`
        }
    }

    async function getData() {
        setDataLoading(true);
        try {
            let res = await axios.get(`${API_URL}/invoices/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });
            setInvoiceObj(res.data);
            setGst(res.data.gst);
            setInvoiceItems(res.data.invoiceItems);
            let date = res.data.invoice_date;
            setInvoiceDate(`${MONTH[parseInt(date.substr(5,2))]} ${returnDateString(date.substr(8,2))}, ${date.substr(0,4)}`)

            let res2 = await axios.get(`${API_URL}/customers/${res.data.customer_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });
            setCustomerObj(res2.data);

            setDataLoading(false);

        } catch(error) {
            setDataLoading(false);
        }        
    }

    useEffect(() => {
        if(id) {
            getData();
        }
    }, [id]);


    const [displayInvoiceItems, setDisplayInvoiceItems] = useState([]);
    
    useEffect(() => {
        let display_array = [];

        let sub = 0;
        
        for(let i = 0; i < invoiceItems.length; i++) {
            let item = invoiceItems[i];

            let amount = parseInt(item.quantity) * parseInt(item.rate);
            let tax = amount * gst;
            sub += amount;

            display_array.push(
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.type}</td>
                    <td>{item.job_no}</td>
                    <td>{item.description}</td>
                    <td style={{textAlign: 'right'}}>{item.quantity}</td>
                    <td style={{textAlign: 'right'}}>{item.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td style={{textAlign: 'right'}}>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td style={{textAlign: 'right'}}>{tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
            )
        }

        setDisplayInvoiceItems(display_array);

        setSubtotal(sub); 
        setTotal(sub + (sub * gst));

    }, [invoiceItems, gst]);

    return (
        <div className='app-container'>
            <Navigation />

            <Container maxWidth='lg'>
                <Grid container style={{
                    paddingBottom:'20px',
                }}>
                    <Grid item xs={6}>
                        <h3>Invoice Report</h3>
                        <h3 style={{
                            fontWeight:400,
                            color:'#777777',
                            fontSize: '0.9rem',
                        }}>ID {id}</h3>
                    </Grid>
                    <Grid item xs={6} style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <Button 
                          disabled={dataLoading}
                          variant="contained" 
                          color="primary" 
                          disableElevation 
                          style={{
                              padding: 0,
                              fontSize: 15,
                          }} >
                            <PDFDownloadLink 
                                  document={
                                    <InvoiceDocument 
                                        invoice_no={invoiceObj.invoice_no}
                                        gst={gst}
                                        total={total}
                                        invoice_po_no={invoiceObj.invoice_po_no} 
                                        invoice_terms={invoiceObj.invoice_terms}
                                        invoice_date={invoiceDate}
                                        subtotal={subtotal}
                                        items={invoiceItems}
                                        customer={
                                        {
                                            company: invoiceObj.customer,
                                            address: customerObj.billing_address,
                                            name: customerObj.contact_name,
                                            email: customerObj.billing_email,
                                            phone: customerObj.contact_phone,
                                        }
                                        } />
                                  } 
                                  fileName={`EV-${id}`}
                                  style={{
                                      color: 'white',
                                      textDecoration: 'none',
                                      display: 'table',
                                      padding: '8px 22px',
                                  }} >
                                    {({ blob, url, loading, error }) =>
                                        (loading || dataLoading) ? (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: '#777777',
                                              }}>
                                                <CircularProgress disableShrink size={20} style={{
                                                    marginRight: 10,
                                                    marginLeft: -5,
                                                    color: '#777777',
                                                }} />
                                                Loading
                                            </div>
                                        ) : 'Download PDF'
                                    }
                            </PDFDownloadLink>
                        </Button>
                    </Grid>
                </Grid>

                <div 
                  style={{
                    border:'1px solid #EEEEEE',
                    marginBottom: '2vw',
                    padding: 24,
                    lineHeight: '150%',
                  }}>
                    <Grid container>
                        <Grid item xs={7}>
                            <img src={Whitelabel} />
                            <br />
                            202-8850 University Cr. Burnaby V5A 0C8<br />
                            P: 604-836-3351 peter@maxengineering.ca
                        </Grid>
                        <Grid item xs={2} style={{
                            display: 'flex', 
                            alignItems: 'center',
                        }}>
                            <div>
                                <div style={{fontWeight:700}}>Date</div>
                                {invoiceDate}
                            </div>
                        </Grid>
                        <Grid item xs={2} style={{
                            display: 'flex', 
                            alignItems: 'center',
                        }}>
                            <div>
                                <div style={{fontWeight:700}}>Invoice #</div>
                                <div style={{
                                    textTransform: 'uppercase',
                                }}>
                                    {invoiceObj.invoice_no}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={7} style={{
                            paddingTop:50
                        }}>
                            <div style={{fontWeight:700}}>Bill To</div>
                            {invoiceObj.customer} <br />
                            {customerObj.billing_address} <br />
                            Attention: {customerObj.contact_name} <br />
                            {customerObj.billing_email} <br />
                            {customerObj.contact_phone} <br />
                        </Grid>
                        <Grid item xs={2} style={{
                            paddingTop:50
                        }}>
                            <div>
                                <div style={{fontWeight:700}}>P.O. No.</div>
                                {invoiceObj.invoice_po_no}
                            </div>
                        </Grid>
                        <Grid item xs={2} style={{
                            paddingTop:50
                        }}>
                            <div>
                                <div style={{fontWeight:700}}>Term</div>
                                {invoiceObj.invoice_terms}
                            </div>
                        </Grid>

                        <Grid item style={{
                            paddingTop: 50,
                        }} xs={12}>
                            <table style={{
                                width:'100%',
                                textAlign: 'left',
                            }}>
                                <tr>
                                    <th>Line #</th>
                                    <th>Job Type</th>
                                    <th>Job No</th>
                                    <th>Job Description</th>
                                    <th style={{textAlign: 'right'}}>Hour</th>
                                    <th style={{textAlign: 'right'}}>Rate</th>
                                    <th style={{textAlign: 'right'}}>Amount</th>
                                    <th style={{textAlign: 'right'}}>GST</th>
                                </tr>
                                {displayInvoiceItems}
                            </table>
                        </Grid>

                        <Grid item xs={12} style={{
                            paddingTop: 50,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <table>
                                <tr>
                                    <td style={{fontWeight: 600, paddingRight: 50}}>Subtotal</td>
                                    <td style={{textAlign: 'right'}}>{`$${subtotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: 600, paddingRight: 50}}>GST ({(gst * 100) + '%'})</td>
                                    <td style={{textAlign: 'right'}}>{`$${(gst * subtotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: 600, paddingRight: 50}}>Total</td>
                                    <td style={{textAlign: 'right'}}>{`$${total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                                </tr>
                            </table>
                        </Grid>
                    </Grid>
                </div>
{/* 
                <PDFViewer style={{
                    width: '100%',
                    height: 1200
                }}>
                    
                <InvoiceDocument 
                                        
                                        invoice_no={invoiceObj.invoice_no} 
                                        invoice_po_no={invoiceObj.invoice_po_no} 
                                        invoice_terms={invoiceObj.invoice_terms}
                                        gst={gst}
                                        total={total}
                                        subtotal={subtotal}
                                        items={invoiceItems}
                                        customer={
                                        {
                                            company: invoiceObj.customer,
                                            address: customerObj.billing_address,
                                            name: customerObj.contact_name,
                                            email: customerObj.billing_email,
                                            phone: customerObj.contact_phone,
                                        }
                                        } />
                </PDFViewer> */}
                
            </Container>
        </div>
    )
}

const InvoiceDocument = (props) => {
    
    const { customer, items, invoice_no, invoice_po_no, invoice_terms, gst, total, subtotal, invoice_date } = props;

    Font.register({ family: 'Inter', fonts: [
        { src: InterReg }, 
        { src: InterMed, fontWeight: 500},
        { src: InterBold, fontWeight: 600 },
    ]});

    
    // PDF Report
    const styles = StyleSheet.create({
        document: {
            width: '100%',
            fontSize: 10,
        },
        page: {
            flexDirection: 'column',
            backgroundColor: '#FFFFFF'
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            fontWeight: 400,
            fontFamily: 'Inter',
        },
        smallBox: {
            width: 120
        },
        topBox: {
            paddingTop:15,
        },
        image: {
            width: 200
        },
        table: {
            padding: '0 20px 20px 20px',
            boxSizing: 'border-box',
            width: '100%',
        }, 
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        inline: {
            display: 'table',
            fontWeight: 400,
            fontFamily: 'Inter',
            boxSizing: 'border-box',
            padding: '10px 5px',
            borderRight: '1px solid #EEEEEE',
            fontSize: 10,
        },
        right: {
            textAlign: 'right',
        },
        colOne: {
            width:'12%',
        },
        colThree: {
            width: '25%',
        }, 
        colTwo: {
            width:'14%',
        },
        colFive: {
            width:'40%',
        },
        head: {
            borderBottom: '1px solid #CCCCCC',
            backgroundColor:'#EEEEEE',
        },
        headLine: {
            fontWeight: 500,
            borderRight: '1px solid #CCCCCC',
        },
        containertable: {
            padding: '0 20px 20px 20px',
            boxSizing: 'border-box',
            width: '100%',
        },
        colLast: {
            borderRight: 'none',
        },
        bold: {
            fontWeight: 600,
            paddingBottom: 5
        },
        footer: {
            padding:20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        aggregateBox: {
            fontWeight: 400,
            fontFamily: 'Inter',
            display:'flex',
            flexDirection: 'row',
            border: '1px solid #CCCCCC',
        },
        aggregateColLabel: {
            fontWeight: 500,
            borderRight: '1px solid #CCCCCC',
            backgroundColor:'#EEEEEE',
        },
        footerText: {
            padding:'8px 20px 8px 10px',
        },
        footerTextLabel: {
            paddingRight: 20,
            paddingLeft:10,
        },
        borderBottomDark: {
            borderBottom: '1px solid #CCCCCC'
        },
        borderBottomLight: {
            borderBottom: '1px solid #EEEEEE'
        },
    });

    const [displayInvoiceItems, setDisplayInvoiceItems] = useState([]);
    
    useEffect(() => {
        let display_array = [];
        
        for(let i = 0; i < items.length; i++) {
            let item = items[i];

            let amount = parseInt(item.quantity) * parseInt(item.rate);
            let tax = amount * gst;

            display_array.push(
                <View style={[styles.row, {
                    borderBottom: ((items.length - 1) === i) ? '1px solid #FFFFFF' : '1px solid #EEEEEE'
                }]} key={i}>
                    <Text break style={[styles.inline, styles.colOne]}>{i + 1}</Text>
                    <Text style={[styles.inline, styles.colThree]}>{item.type}</Text>
                    <Text style={[styles.inline, styles.colTwo]}>{item.job_no}</Text>
                    <Text style={[styles.inline, styles.colFive]}>{item.description}</Text>
                    <Text style={[styles.inline, styles.right, styles.colOne]}>{item.quantity}</Text>
                    <Text style={[styles.inline, styles.right, styles.colOne]}>{parseInt(item.rate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                    <Text style={[styles.inline, styles.right, styles.colOne]}>{amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                    <Text style={[styles.inline, styles.right, styles.colOne, styles.colLast]}>{tax.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                </View>
            );
        }

        setDisplayInvoiceItems(display_array);

    }, [items])

    return (
        <Document style={styles.document}>
            <Page size="Letter" style={styles.page}>
                <View style={[styles.container, {
                    paddingTop: 30
                }]}>
                    <View style={styles.section}>
                        <Image src={Whitelabel} style={styles.image} />
                        <Text style={{
                            paddingBottom: 2
                        }}>202-8850 University Cr. Burnaby V5A 0C8</Text>
                        <Text>P: 604-836-3351 peter@maxengineering.ca</Text>
                    </View>
                    <View style={styles.container}>
                        <View style={[styles.section, styles.smallBox, styles.topBox]}>
                            <Text style={styles.bold}>Date</Text>
                            <Text>{invoice_date}</Text>
                        </View>
                        <View style={[styles.section, styles.smallBox, styles.topBox]}>
                            <Text style={styles.bold}>Invoice #</Text>
                            <Text>{invoice_no}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.container]}>
                    <View style={styles.section}>
                        <Text style={styles.bold}>Bill To</Text>
                        <Text>{customer.company}</Text>
                        <Text>{customer.address}</Text>
                        <Text>Attention: {customer.name}</Text>
                        <Text>{customer.email}</Text>
                        <Text>{customer.phone}</Text>
                    </View>
                    <View style={styles.container}>
                        <View style={[styles.section, styles.smallBox]}>
                            <Text style={styles.bold}>P.O. No.</Text>
                            <Text>{invoice_po_no}</Text>
                        </View>
                        <View style={[styles.section, styles.smallBox]}>
                            <Text style={styles.bold}>Term</Text>
                            <Text>{invoice_terms}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.containertable}>
                    <View 
                      style={{
                        marginTop: 15,
                        border:'1px solid #CCCCCC',
                      }}>
                        <View style={[styles.head, styles.row]}>
                            <Text style={[styles.inline, styles.colOne, styles.headLine]}>Line #</Text>
                            <Text style={[styles.inline, styles.colThree, styles.headLine]}>Job Type</Text>
                            <Text style={[styles.inline, styles.colTwo, styles.headLine]}>Job No.</Text>
                            <Text style={[styles.inline, styles.colFive, styles.headLine]}>Job Description</Text>
                            <Text style={[styles.inline, styles.right, styles.colOne, styles.headLine]}>Hour</Text>
                            <Text style={[styles.inline, styles.right, styles.colOne, styles.headLine]}>Rate</Text>
                            <Text style={[styles.inline, styles.right, styles.colOne, styles.headLine]}>Amt.</Text>
                            <Text style={[styles.inline, styles.right, styles.colOne, styles.headLine, styles.colLast]}>GST</Text>
                        </View>
                        {displayInvoiceItems}
                    </View>
                </View>
                <View style={styles.footer}>
                    <View style={styles.aggregateBox}>
                        <View style={[styles.aggregateCol, styles.aggregateColLabel]}>
                            <Text style={[styles.footerText, styles.footerTextLabel, styles.borderBottomDark]}>Subtotal</Text>
                            <Text style={[styles.footerText, styles.footerTextLabel, styles.borderBottomDark]}>GST ({`${gst * 100}%`})</Text>
                            <Text style={[styles.footerText, styles.footerTextLabel]}>Total</Text>
                        </View>
                        <View style={styles.aggregateCol}>
                            <Text style={[styles.footerText, styles.borderBottomLight]}>{subtotal ? `$${subtotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ''}</Text>
                            <Text style={[styles.footerText, styles.borderBottomLight]}>{(subtotal && gst) ? `$${(subtotal * gst).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ''}</Text>
                            <Text style={[styles.footerText]}>{total ? `$${total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ''}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default Invoice;
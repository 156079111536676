import React from 'react';
import { Container } from '@material-ui/core';
import Navigation from '../components/Navigation';

const Inventory = () => {
    return (
        <div className='app-container'>
            <Navigation />

            
            <Container maxWidth='lg'>
                <h3>Inventory</h3>
                
            </Container>
        </div>
    )
}

export default Inventory;
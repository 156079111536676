import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Button, Snackbar, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Navigation from '../components/Navigation';
import AccountDialog from '../components/AccountDialog';
import DeleteDialog from '../components/DeleteDialog';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const Accounts = () => {

    const { currentUser } = useAuthContext();

    const [dataRefresh, setDataRefresh] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [accountsData, setAccountsData] = useState([]);

    const [displayAccounts, setDisplayAccounts] = useState([]);

    async function loadAccounts() {
        setDataLoading(true);

        try {
            let res = await axios.get(`${API_URL}/auth/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setAccountsData(res.data);
            setDataLoading(false);

        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadAccounts();
    }, [dataRefresh]);



    // Account card functions

    function handleAccountUpdate(account) {
        handleOpenDialog('edit', account);
    }

    function handleAccountDelete(id) {
        handleDeleteAccount(id);
    }
    
    // Display account cards
    useEffect(() => {
        let display_array = [];

        for (let i = 0; i < accountsData.length; i++) {
            display_array.push(
                <Grid item xs={4}>
                    <div style={{
                        border: '1px solid #EEEEEE',
                    }}>
                        <div style={{
                            padding: '70px 20px 15px 20px',
                            background: '#0048DE',
                            color: '#FFFFFF',
                            display: 'flex',
                            fontSize: '1rem',
                            alignItems: 'center',
                            fontWeight: 500,
                            flexWrap: 'wrap',
                        }}>
                            {accountsData[i].email}

                            <div style={{
                                fontSize: '0.75rem',
                                letterSpacing: 0.5,
                                fontWeight: 500,
                                marginLeft: 10,
                                padding: 5,
                                background: '#0053FF',
                                color:'#FFFFFF',
                            }}>
                                {(accountsData[i].role)}
                            </div>
                        </div>
                        <div style={{
                            padding: 5,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button
                              size="large"
                              variant="text"
                              color="primary"
                              style={{
                                fontSize: '0.9rem',
                              }}
                              onClick={e=>handleAccountUpdate(accountsData[i])} >
                                Update
                            </Button>
                            {(accountsData[i].role === 'ADMIN') ? '' : (
                                <Button
                                size="large"
                                variant="text"
                                color="secondary"
                                style={{
                                    marginLeft: 5,
                                }}
                                onClick={e=>handleAccountDelete(accountsData[i]._id)} >
                                    Delete
                                </Button>
                            )}
                        </div>
                    </div>
                </Grid>
            )
        }

        setDisplayAccounts(display_array);

    }, [accountsData]);



    // DIALOG
    const [openedDialog, setOpenedDialog] = useState(false);
    const [dialogType, setDialogType] = useState(''); // "edit"/"create"

    const [UID, setUID] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function handleOpenDialog(type, account) {

        if(type === 'edit') {
            setUID(account._id);
            setEmail(account.email);

            setDialogType('edit');
            setOpenedDialog(true);
        }

        if(type ==='create') {
            setDialogType('create');
            setOpenedDialog(true);
        }

    }

    function handleCloseDialog() {
        setEmail('');
        setPassword('');

        setOpenedDialog(false);
    }

    function handleSaveDialog(type) {

        if(type === 'create') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseDialog();
            setNotifySuccess({
                severity: 'success',
                message: 'Account created successfully!',
            });
        } else if (type === 'edit') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseDialog();
            setNotifySuccess({
                severity: 'success',
                message: 'Account edited successfully!',
            });
        }

    }



    // Delete confirmation
    const [openedDelete, setOpenedDelete] = useState(false);
    const [confirmID, setConfirmID] = useState('');

    // main function - opens delete dialog
    const handleDeleteAccount = (id) => {
        setOpenedDelete(true);
        setConfirmID(id);
    }

    // close situations: didn't delete
    const handleDeleteClose = () => {
        setOpenedDelete(false);
    }

    // close situation: after deletion (dialog components handles axios)
    const handleDeleteConfirm = () => {
        setDataRefresh(dataRefresh ? false : true);
        setOpenedDelete(false);
        setNotifySuccess({
            severity: 'success',
            message: 'Account deleted successfully!',
        });
    }



    // Dialog Feedback
    const [notifySuccess, setNotifySuccess] = useState({severity: 'closed', message: ''});


    return (
        <>
            {dataLoading ? (
                <div style={{
                    width: '100%',
                    height: 0,
                }}>
                    <LinearProgress />
                </div>
            ) : ''}
            <div className='app-container'>

                <Navigation />

                <Container maxWidth='lg'>
                    <Grid container style={{
                        paddingBottom:'20px',
                    }}>
                        <Grid item xs={6}>
                            <h3>Accounts</h3>
                        </Grid>
                        <Grid item xs={6} style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}>
                            <Button variant="contained" color="primary" size="large" disableElevation onClick={e=>handleOpenDialog('create')}>
                                Create Account
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{
                      paddingTop: '2vw',
                    }}>
                        {displayAccounts}
                    </Grid>
                    
                </Container>

                <AccountDialog
                    userID={UID}
                    email={email}
                    password={password}
                    opened={openedDialog}
                    type={dialogType}
                    onClose={handleCloseDialog}
                    onSave={handleSaveDialog} />
                
                <DeleteDialog
                    deleteType='account'
                    confirmID={confirmID}
                    opened={openedDelete}
                    onClose={handleDeleteClose}
                    onConfirm={handleDeleteConfirm} />

                <Snackbar open={(notifySuccess.severity !== 'closed')} autoHideDuration={4000} onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})}>
                    <Alert onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})} severity={notifySuccess.severity}>
                        {notifySuccess.message}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

export default Accounts;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Dialog,
    Container,
    Button, 
    DialogTitle,
    TextField,
    IconButton,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
import CloseIcon from '@material-ui/icons/Close';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const JobDialog = (props) => {

    const { currentUser } = useAuthContext();


    const [displayCustomers, setDisplayCustomers] = useState('');

    useEffect(() => {

        let display_array = [];

        for(let i = 0; i < props.customersData.length; i++) {
            display_array.push(
                <MenuItem value={props.customersData[i]._id} name={props.customersData[i].name}>{props.customersData[i].name}</MenuItem>
            )
        }

        setDisplayCustomers(display_array);
    }, [props]);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');

    const [JBID, setJBID] = useState(props.jobID);
    const [jobNo, setJobNo] = useState(props.jobNo);
    const [initJobNo, setInitJobNo] = useState(props.jobNo);
    const [customer, setCustomer] = useState(props.customer);
    const [description, setDescription] = useState(props.description);
    const [isAnalysis, setIsAnalysis] = useState(props.isAnalysis === 'true');
    const [isDesign, setIsDesign] = useState(props.isDesign === 'true');
    const [note, setNote] = useState(props.note);
    const [rate, setRate] = useState(props.rate);
    const [quantity, setQuantity] = useState(props.quantity);
    const [customerID, setCustomerID] = useState('');
    const [jobType, setJobType] = useState(props.jobType);

    const [isFullscreen, toggleFullscreen] = useState(false);

    const { type, opened, onClose, onSave } = props;
    
    useEffect(() => {
        setJBID(props.jobID);
        setJobNo(props.jobNo)
        setInitJobNo(props.jobNo);
        setCustomer(props.customer);
        setCustomerID(props.customerID);
        setJobType(props.jobType);
        setDescription(props.description);
        setQuantity(props.quantity);
        setIsAnalysis(props.isAnalysis);
        setIsDesign(props.isDesign);
        setNote(props.note);
        setRate(props.rate);
    }, [opened]);

    const clear = () => {
        setJBID('');
        setJobNo('');
        setInitJobNo('');
        setCustomer('');
        setCustomerID('');
        setJobType('');
        setDescription('');
        setQuantity('');
        setIsAnalysis(false);
        setIsDesign(false);
        setNote('');
        setRate('');
        setError('');
        toggleFullscreen(false);
    }

    const handleClose = () => {
        clear();
        onClose();
    }

    const handleSelectCustomer = (e) => {
        if(e.target.value) {
            setCustomer(props.customersData.find((o) => (o._id === e.target.value)).name);
            setCustomerID(e.target.value);
        }
    }
    

    // handles both Creates + Edits
    async function handleSave() {
        setError('');

        if(!loading) {
            setLoading(true);
            
            let postBody = {
                quantity: quantity,
                customer: customer,
                type: jobType,
                customer_id: customerID,
                description: description,
                note: note,
                is_analysis: isAnalysis,
                is_design: isDesign,
                rate: rate,
            };

            if(type === 'edit') {

                if(initJobNo !== jobNo) {
                    postBody['job_no'] = jobNo;
                }

                try {
                    let res = await axios.patch(`${API_URL}/jobs/${JBID}`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });

                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Connection with server is bad');
                    }
                }

            } else if (type === 'create') {
                postBody['job_no'] = jobNo;
            
                try {
                    let res = await axios.post(`${API_URL}/jobs/`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });
    
                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Error connecting to server');
                    }
                }
            }
        }
    }

    return (
        <Dialog fullScreen={isFullscreen} onClose={onClose} aria-labelledby="customized-dialog-title" open={opened}>
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <IconButton edge='start' aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <h4 style={{
                        margin: '0 1.5vw 0 10px',
                    }}>
                        {(type === 'edit') ? 'Edit Job' : 'Create Job'}
                    </h4>
                </div>
                <div className='dialg-title-action'>
                    <Button onClick={e=>toggleFullscreen(isFullscreen ? false : true)} size="large" color="primary" variant="outlined">
                        { (isFullscreen) ? 'Shrink Window' : 'Make Fullscreen' }
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Container style={{ padding:'0 0 1.5vw 0' }}
                    maxWidth={(isFullscreen) ? 'md' : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '1.5vw 0 0 0',
                            }}>
                                {(type === 'edit') ? 'Edit Existing Job' : 'Create New Job'}
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={jobNo} onChange={e=>setJobNo(e.target.value)} variant="outlined" label="Job Number" fullWidth margin="normal" />
                            <FormControl 
                                required
                                variant="outlined"
                                margin="normal" 
                                fullWidth>
                                <InputLabel id="customer-label">Customer</InputLabel>
                                <Select
                                    labelId="customer-label"
                                    value={customerID}
                                    onChange={handleSelectCustomer} 
                                    label="Customer">
                                    <MenuItem value="">
                                        <em>Select Customer</em>
                                    </MenuItem>
                                    {displayCustomers}
                                </Select>
                            </FormControl>
                            <FormControl 
                                required
                                variant="outlined"
                                margin="normal" 
                                fullWidth>
                                <InputLabel id="customer-label">Job Type</InputLabel>
                                <Select
                                    labelId="customer-label"
                                    value={jobType}
                                    onChange={e=>{if(e.target.value) {setJobType(e.target.value); }}} 
                                    label="Customer">
                                    <MenuItem value="">
                                        <em>Select Job Type</em>
                                    </MenuItem>
                                    <MenuItem value='Product Design'>Product Design</MenuItem>
                                    <MenuItem value='Product Sales'>Product Sales</MenuItem>
                                    <MenuItem value='Engineering'>Engineering</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <FormGroup style={{marginTop:20}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankSharpIcon />}
                                            checkedIcon={<CheckBoxSharpIcon />}
                                            checked={isAnalysis}
                                            onChange={e=>setIsAnalysis(isAnalysis ? false : true)}
                                            color="primary" />
                                    }
                                    label="Is Analysis?" />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankSharpIcon />}
                                            checkedIcon={<CheckBoxSharpIcon />}
                                            checked={isDesign}
                                            onChange={e=>setIsDesign(isDesign ? false : true)}
                                            color="primary" />
                                    }
                                    label="Is Design?" />
                            </FormGroup> */}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={rate} onChange={e=>setRate(e.target.value)} variant="outlined" label="Rate" fullWidth margin="normal"  />
                            <TextField value={quantity} onChange={e=>setQuantity(e.target.value)} variant="outlined" label="Quantity" fullWidth margin="normal"  />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField multiline value={description} onChange={e=>setDescription(e.target.value)} variant="outlined" label="Description" fullWidth margin="normal" />
                            <TextField multiline value={note} onChange={e=>setNote(e.target.value)} variant="outlined" label="Note" fullWidth margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button
                  disabled={loading}
                  onClick={e=>{
                    if(opened) {
                        handleSave();
                    }
                  }} 
                  color="primary" 
                  variant="contained" 
                  size="large" 
                  style={{
                      minWidth:'150px'
                  }}
                  disableElevation>
                    {!loading ? ((type === 'edit') ? 'Save Changes' : 'Create Job') : (
                        <>
                            <CircularProgress disableShrink size={20} style={{
                                marginRight: 10,
                                marginLeft: -5,
                                color: '#777777',
                            }} />
                            Loading
                        </>
                    )}
                </Button>
            </DialogActions>
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
        </Dialog>
    );
}

export default JobDialog;
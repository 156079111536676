import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Container,
    Button, 
    DialogTitle,
    TextField,
    IconButton,
    DialogContent,
    DialogActions,
    Grid,
    CircularProgress,
    Divider
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const CustomerDialog = (props) => {

    const { currentUser } = useAuthContext();

    const [CUID, setCUID] = useState(props.customerID); // Customer ID
    const [customer, setCustomer] = useState(props.customer);
    const [customerInitial, setCustomerInitial] = useState(props.customer); // to check against editing
    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [address, setAddress] = useState(props.address);
    const [city, setCity] = useState(props.city);
    const [province, setProvince] = useState(props.province);
    const [postal, setPostal] = useState(props.postal);
    const [phone, setPhone] = useState(props.phone);

    const [isFullscreen, toggleFullscreen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { type, opened, onClose, onSave } = props;
    
    useEffect(() => {
        setCUID(props.customerID);
        setCustomer(props.customer);
        setName(props.name);
        setEmail(props.email);
        setAddress(props.address);
        setCity(props.city);
        setProvince(props.province);
        setPostal(props.postal);
        setPhone(props.phone);
    }, [opened]);

    const clear = () => {
        setCUID('');
        setCustomer('');
        setName('');
        setEmail('');
        setAddress('');
        setCity('');
        setProvince('');
        setPostal('');
        setPhone('');
        setError('');
        toggleFullscreen(false);
    }

    const handleClose = () => {
        clear();
        onClose();
    }

    // handles both Creates + Edits
    async function handleSave() {
        setError('');

        if(!loading) {
            setLoading(true);

            let postBody = {
                billing_address: address,
                billing_city: city,
                billing_province: province,
                billing_postal: postal,
                billing_email: email,
                contact_name: name,
                contact_phone: phone,
            }

            if(type === 'edit') {                
                if(customerInitial !== customer) {
                    // checking if the customer name is updated (lower performance/need to rename all jobs if it is the case)
                    postBody.name = customer; 
                }

                try {
                    let res = await axios.patch(`${API_URL}/customers/${CUID}`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });
    
                    setLoading(false);
                    
                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Error connecting to server');
                    }
                }

            } else if (type === 'create') {
                postBody.name = customer;
            
                try {
                    let res = await axios.post(`${API_URL}/customers/`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });
    
                    setLoading(false);
                    
                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Error connecting to server');
                    }
                }
            }
        }
    }

    return (
        <Dialog fullScreen={isFullscreen} onClose={onClose} aria-labelledby="customized-dialog-title" open={opened} >
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <IconButton edge='start' aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <h4 style={{
                        margin: '0 1.5vw 0 10px',
                    }}>
                        {(type === 'edit') ? 'Edit Customer' : 'Create New Customer'}
                    </h4>
                </div>
                <div className='dialg-title-action'>
                    <Button onClick={e=>toggleFullscreen(isFullscreen ? false : true)} size="large" color="primary" variant="outlined">
                        { (isFullscreen) ? 'Shrink Window' : 'Make Fullscreen' }
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Container style={{ padding:'0 0 1.5vw 0' }}
                    maxWidth={(isFullscreen) ? 'md' : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '1.5vw 0 0 0',
                            }}>
                                {(type === 'edit') ? 'Edit Existing Customer' : 'Create New Customer'}
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={customer} onChange={e=>setCustomer(e.target.value)} variant="outlined" label="Customer" fullWidth margin="normal" />
                            <TextField required value={email} onChange={e=>setEmail(e.target.value)} variant="outlined" label="Billing Email" fullWidth margin="normal"  />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={name} onChange={e=>setName(e.target.value)} variant="outlined" label="Contact Name" fullWidth margin="normal" />
                            <TextField required value={phone} onChange={e=>setPhone(e.target.value)} variant="outlined" label="Contact Phone" fullWidth margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '1.5vw 0 0 0',
                            }}>
                                Billing Address
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={address} onChange={e=>setAddress(e.target.value)} variant="outlined" label="Address" fullWidth margin="normal" />
                            <TextField required value={city} onChange={e=>setCity(e.target.value)} variant="outlined" label="City" fullWidth margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={province} onChange={e=>setProvince(e.target.value)} variant="outlined" label="Province" fullWidth margin="normal" />
                            <TextField required value={postal} onChange={e=>setPostal(e.target.value)} variant="outlined" label="Postal Code" fullWidth margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button
                  disabled={loading}
                  onClick={e=>{
                    if(opened) {
                        handleSave();
                    }
                  }} 
                  color="primary" 
                  variant="contained" 
                  size="large" 
                  style={{
                      minWidth:'150px'
                  }}
                  disableElevation>
                    {!loading ? ((type === 'edit') ? 'Save Changes' : 'Create Customer') : (
                        <>
                            <CircularProgress disableShrink size={20} style={{
                                marginRight: 10,
                                marginLeft: -5,
                                color: '#777777',
                            }} />
                            Loading
                        </>
                    )}
                </Button>
            </DialogActions>
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
        </Dialog>
    );
}

export default CustomerDialog;
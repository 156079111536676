import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Dialog,
    Container,
    Button, 
    DialogTitle,
    TextField,
    IconButton,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem,
    CircularProgress
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
import CloseIcon from '@material-ui/icons/Close';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const AccountDialog = (props) => {

    const { currentUser } = useAuthContext();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [UID, setUID] = useState(props.userID);
    const [email, setEmail] = useState(props.email);
    const [initEmail, setInitEmail] = useState(props.email);
    const [password, setPassword] = useState(props.password);

    const [isFullscreen, toggleFullscreen] = useState(false);

    const { type, opened, onClose, onSave } = props;
    
    useEffect(() => {
        setUID(props.userID)
        setEmail(props.email);
        setInitEmail(props.email);
        setPassword(props.password)
    }, [opened]);

    const clear = () => {
        setUID('')
        setEmail('');
        setInitEmail('');
        setPassword('');
        
        setError('');
        toggleFullscreen(false);
    }

    const handleClose = () => {
        clear();
        onClose();
    }

    

    // handles both Creates + Edits
    async function handleSave() {
        setError('');

        if(!loading) {
            setLoading(true);
            
            let postBody = {};

            if(type === 'edit') {

                if(initEmail !== email) {
                    postBody['email'] = email;
                }
                if(password !== '') {
                    postBody['password'] = password;
                }

                try {
                    let res = await axios.patch(`${API_URL}/auth/user/${UID}`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });

                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Connection with server is bad');
                    }
                }

            } else if (type === 'create') {

                postBody['email'] = email;
                postBody['password'] = password;
            
                try {
                    let res = await axios.post(`${API_URL}/auth/register`, postBody, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });
    
                    setLoading(false);

                    clear();
                    onSave(type);
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Error connecting to server');
                    }
                }
            }
        }
    }

    return (
        <Dialog fullScreen={isFullscreen} onClose={onClose} aria-labelledby="customized-dialog-title" open={opened}>
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <IconButton edge='start' aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <h4 style={{
                        margin: '0 1.5vw 0 10px',
                    }}>
                        {(type === 'edit') ? 'Update Account' : 'Create Account'}
                    </h4>
                </div>
                <div className='dialg-title-action'>
                    <Button onClick={e=>toggleFullscreen(isFullscreen ? false : true)} size="large" color="primary" variant="outlined">
                        { (isFullscreen) ? 'Shrink Window' : 'Make Fullscreen' }
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Container style={{ padding:'0 0 1.5vw 0' }}
                    maxWidth={(isFullscreen) ? 'md' : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3 style={{
                                margin: '1.5vw 0 0 0',
                            }}>
                                {(type === 'edit') ? 'Edit Account Job' : 'Create New Account'}
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={email} onChange={e=>setEmail(e.target.value)} variant="outlined" label="Email" fullWidth margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required value={password} onChange={e=>setPassword(e.target.value)} variant="outlined" label={(props.type === 'edit') ? 'New Password' : 'Password'} fullWidth margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button
                  disabled={loading}
                  onClick={e=>{
                    if(opened) {
                        handleSave();
                    }
                  }} 
                  color="primary" 
                  variant="contained" 
                  size="large" 
                  style={{
                      minWidth:'150px'
                  }}
                  disableElevation>
                    {!loading ? ((type === 'edit') ? 'Update Account' : 'Create Account') : (
                        <>
                            <CircularProgress disableShrink size={20} style={{
                                marginRight: 10,
                                marginLeft: -5,
                                color: '#777777',
                            }} />
                            Loading
                        </>
                    )}
                </Button>
            </DialogActions>
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
                {(props.type === 'edit') ? (
                    <Alert severity="info">Passwords cannot be viewed. Leave the password field blank if wanting to keep it the same.</Alert>
                ) : ''}
        </Dialog>
    );
}

export default AccountDialog;
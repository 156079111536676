import React, { useState, useEffect } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';


const CustomerList = (props) => {

    function LinearLoadingOverlay() {
      return (
        <GridOverlay>
          <div style={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}>
            <LinearProgress />
          </div>
        </GridOverlay>
      )
    }
  
    const columns = [
      { field: '_id', headerName: 'ID', flex: 1, },
      { field: 'name', headerName: 'Customer', flex: 2 },
      { field: 'billing_address', headerName: 'Billing Address', flex: 4 },
      { field: 'billing_email', headerName: 'Billing Email', flex: 2 },
      { field: 'contact_name', headerName: 'Name', flex: 2 },
      { field: 'contact_phone', headerName: 'Phone', flex: 2 },
      {
        field: 'action',
        type: 'number',
        sortable: false,
        flex: 2,
        renderCell: (params) => (
          <div style={{
              display: 'table',
              paddingRight: 5,
              overflow: 'auto',
          }}>
            <Button
              variant="text"
              color="primary"
              onClick={e=>params.value.edit_fn(params.value.customer_obj)}>
                Edit
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={e=>params.value.delete_fn(params.value.customer_obj._id)}>
                Delete
            </Button>
          </div>
        ),
      },
    ];

    const [rows, setRows] = useState(props.data);

    useEffect(() => {
      setRows(props.data);
    }, [props.loading, props.refresh]);

    return (
    
            <DataGrid 
                getRowId={(row)=>row._id}
                loading={props.loading}
                autoHeight
                rows={rows} 
                columns={columns}
                density='standard'
                checkboxSelection 
                autoPageSize
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection={false}
                components={{
                  LoadingOverlay: LinearLoadingOverlay,
                }} />
    );
    
}

export default  CustomerList;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';
import Navigation from '../components/Navigation';
import CustomerList from '../components/CustomerList';
import CustomerDialog from '../components/CustomerDialog';
import DeleteDialog from '../components/DeleteDialog';

const Customers = () => {

    const { currentUser } = useAuthContext();

    // Getting data for the table

    const [customersData, setCustomersData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataRefresh, setDataRefresh] = useState(false);

    async function loadCustomers() {
        setDataLoading(true);

        try {
            let res = await axios.get(`${API_URL}/customers/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            let functional_list = res.data.map((item) => {return {...item, action: {customer_obj: item, edit_fn: handleListEdit, delete_fn: handleListDelete}} });
            setCustomersData(functional_list);
            setDataLoading(false);

        } catch(error) {
            setDataLoading(false);
        }
    }

    useEffect(() => {
        loadCustomers();
    }, [dataRefresh]);

    function handleListEdit(customer) {
        handleOpenCustomer('edit', customer);
    }

    function handleListDelete(id) {
        handleDeleteCustomer(id);
    }





    // Customer Dialog
    const [openedCustomer, setOpenedCustomer] = useState(false);
    const [customerDialogType, setCustomerDialogType] = useState('');

    const [customerID, setCustomerID] = useState('');
    const [customer, setCustomer] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [postal, setPostal] = useState('');
    const [phone, setPhone] = useState('');

    function handleOpenCustomer(type, customerObject) {
        // type == create / edit
        if(type === 'create') {
            setCustomerDialogType('create');
        }
        if(type === 'edit') {
            setCustomerID(customerObject._id);
            setCustomer(customerObject.name);
            setName(customerObject.contact_name);
            setEmail(customerObject.billing_email);
            setAddress(customerObject.billing_address);
            setCity(customerObject.billing_city);
            setProvince(customerObject.billing_province);
            setPostal(customerObject.billing_postal);
            setPhone(customerObject.contact_phone);
            setCustomerDialogType('edit');
        }
        setOpenedCustomer(true);
    }

    function handleCloseCustomer() {
        setCustomerID('');
        setCustomer('');
        setName('');
        setEmail('');
        setAddress('');
        setCity('');
        setProvince('');
        setPostal('');
        setPhone('');
        setOpenedCustomer(false);
    }

    function handleSaveCustomer(type) {
        // AXIOS request is sent thru modal -- this is just to carry forward the saving interaction flow

        if(type === 'create') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseCustomer();
            setNotifySuccess({
                severity: 'success',
                message: 'Customer created successfully!',
            });
        } else if (type === 'edit') {
            setDataRefresh(dataRefresh ? false : true);
            handleCloseCustomer();
            setNotifySuccess({
                severity: 'success',
                message: 'Customer edited successfully!',
            });
        }
    }



    // Delete confirmation
    const [openedDelete, setOpenedDelete] = useState(false);
    const [confirmID, setConfirmID] = useState('');

    // main function
    const handleDeleteCustomer = (id) => {
        setOpenedDelete(true);
        setConfirmID(id);
    }

    // close situations: didn't delete
    const handleDeleteClose = () => {
        setOpenedDelete(false);
    }

    // close situation: after deletion (dialog components handles axios)
    const handleDeleteConfirm = () => {
        setDataRefresh(dataRefresh ? false : true);
        setOpenedDelete(false);
        setNotifySuccess({
            severity: 'success',
            message: 'Customer deleted successfully!',
        });
    }



    // Notifications
    const [notifySuccess, setNotifySuccess] = useState({severity: 'closed', message: ''});
    
    return (
        <div className='app-container'>
            <Navigation />
            
            <Container maxWidth='lg'>
                <Grid container style={{
                    paddingBottom:'20px',
                }}>
                    <Grid item xs={6}>
                        <h3>Customers</h3>
                    </Grid>
                    <Grid item xs={6} style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <Button variant="contained" color="primary" size="large" disableElevation onClick={e=>handleOpenCustomer('create')}>
                            Add Customer
                        </Button>
                    </Grid>
                </Grid>

                <CustomerList
                  refresh={dataRefresh}
                  loading={dataLoading}
                  data={customersData} />
                
            </Container>

            <CustomerDialog
                customerID={customerID}
                customer={customer}
                email={email}
                address={address}
                city={city}
                province={province}
                postal={postal}
                name={name}
                phone={phone}
                opened={openedCustomer}
                type={customerDialogType}
                onClose={handleCloseCustomer}
                onSave={handleSaveCustomer} />
            
            <DeleteDialog
                deleteType='customer'
                confirmID={confirmID}
                opened={openedDelete}
                onClose={handleDeleteClose}
                onConfirm={handleDeleteConfirm} />

            <Snackbar open={(notifySuccess.severity !== 'closed')} autoHideDuration={4000} onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})}>
                <Alert onClose={e=>setNotifySuccess({...notifySuccess, severity: 'closed'})} severity={notifySuccess.severity}>
                    {notifySuccess.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Customers;
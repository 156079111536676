import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    CircularProgress,
    Container
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';

const DeleteDialog = (props) => {

    const { currentUser } = useAuthContext();

    const [confirmID, setConfirmID] = useState('');
    const [deleteType, setDeleteType] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { onClose, onConfirm, opened } = props;

    useEffect(() => {
        setConfirmID(props.confirmID);
        setDeleteType(props.deleteType);
    }, [props])

    const clear = () => {
        setConfirmID('');
        setDeleteType('');
        setError('');
    }

    const handleClose = () => {
        clear();
        onClose();
    }
    
    async function handleConfirm() {

        setError('');

        if(deleteType) {
            if(!loading) {
                setLoading(true);
    
                try {
                    let res = await axios.delete(`${API_URL}/${(deleteType === 'customer') ? 'customers' : ( (deleteType === 'job') ? 'jobs' : ((deleteType === 'invoice') ? 'invoices' : ((deleteType === 'account') ? 'auth/user' : '')) )}/${confirmID}`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': currentUser,
                            }
                        });
    
                    setLoading(false);
                    
                    clear();
                    onConfirm();
                } catch(error) {
                    setLoading(false);
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Error connecting to server');
                    }
                }
            }
        } else {
            setError('No item to delete selected');
        }
    }

    return (
        <Dialog
          open={opened}
          onClose={handleClose}>
            <DialogTitle disableTypography>
                <div className='dialog-title-text'>
                    <h4 style={{
                        margin: '10px 0',
                    }}>
                        Delete Confirmation
                    </h4>
                </div>
            </DialogTitle>
          <DialogContent>
              <Container maxWidth="lg">
                Are you sure you want to delete?
              </Container>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              onClick={e=>{handleConfirm()}} 
              color="secondary" 
              variant="contained" 
              size="large" 
              style={{
                  minWidth:'150px'
              }}
              disableElevation>
                {!loading ? 'Confirm and Delete' : (
                    <>
                        <CircularProgress disableShrink size={20} style={{
                            marginRight: 10,
                            marginLeft: -5,
                            color: '#777777',
                        }} />
                        Loading
                    </>
                )}
            </Button>
            <Button onClick={handleClose} variant="contained" disableElevation color="primary" size="large">
              Cancel
            </Button>
          </DialogActions>
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
        </Dialog>
    )
}

export default DeleteDialog;
import React, { useState, useEffect } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';

const InvoiceList = (props) => {

    function LinearLoadingOverlay() {
      return (
        <GridOverlay>
          <div style={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}>
            <LinearProgress />
          </div>
        </GridOverlay>
      )
    }
  
    const columns = [
      // { field: '_id', headerName: 'ID', flex: 0.5, },
      { field: 'invoice_no', headerName: '#', flex: 1, },
      { field: 'customer', headerName: 'Customer', flex: 2 },
      { field: 'invoice_date', headerName: 'Invoiced Date', flex: 2 },
      { field: 'payment_date', headerName: 'Payment Date', flex: 2 },
      { field: 'jobs', headerName: 'Jobs', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      {
        field: 'action',
        type: 'number',
        sortable: false,
        selectable: false,
        flex: 2,
        renderCell: (params) => (
          <div style={{
              display: 'table',
              paddingRight: 5,
              overflow: 'auto',
          }}>
            <Button
              variant="text"
              color="primary"
              onClick={e=>params.value.pdf_fn(params.value.invoice_obj._id)}>
                View
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={e=>params.value.edit_fn(params.value.invoice_obj)}>
                Edit
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={e=>params.value.delete_fn(params.value.invoice_obj._id)}>
                Delete
            </Button>
          </div>
        ),
      },
    ];

    const [rows, setRows] = useState(props.data);
    
    useEffect(() => {
      setRows(props.data);
    }, [props.loading, props.refresh]);

    return (
    
            <DataGrid
                getRowId={(row)=>row._id}
                loading={props.loading}
                autoHeight
                rows={rows} 
                columns={columns} 
                density='standard'
                checkboxSelection 
                autoPageSize
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection={false}
                components={{
                  LoadingOverlay: LinearLoadingOverlay,
                }} />
    );
    
}

export default InvoiceList;